import axios from "axios";

export default {
  state: {
    categorias: [],
  },
  getters: {
    categorias: (state) => state.categorias,
  },
  mutations: {
    setCategorias(state, data) {
      state.categorias = data;
    },
  },
  actions: {
    loadCategorias({ commit }) {
      commit("setLoading", true);
      axios
        .get(`${process.env.VUE_APP_API}/api/categoria/all`)
        .then((response) => {
          commit("setCategorias", response.data.categoria);
          return response.data.categoria;
        })
        .catch((error) => {
          throw error.response.data.categoria;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
  },
};
