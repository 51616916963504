import axios from "axios";
import { Notification, NotificationError } from "@/util/noty";

export default {
  state: {
    stateCategoriasSeguro: [],
  },
  getters: {
    stateCategoriasSeguro: (state) => state.stateCategoriasSeguro,
  },
  mutations: {
    _setCategoriasSeguro(state, data) {
      state.stateCategoriasSeguro = data;
    },
  },
  actions: {
    actionListarCategoriasSeguro({ commit }) {
      axios
        .get(`${process.env.VUE_APP_API}/categorias-seguro`)
        .then((response) => {
          if (response.data.success) {
            commit(
              "_setCategoriasSeguro",
              response.data.data.categorias_seguro
            );
            return response.data.data.categorias_seguro;
          } else {
            Notification(
              "danger",
              response.data.data.message.title,
              response.data.data.message.description
            );
          }
        })
        .catch((error) => {
          NotificationError();
          throw error.response.data;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
  },
};
