<template>
  <a-layout>
    <div
      class="container d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white"
    >
      <router-link to="/">
        <img :src="this.manasLogo" alt="" width="180" />
      </router-link>

      <div
        style="position: absolute; right: 20px; top: 50px"
        class="d-lg-none d-xl-none"
      >
        <a-icon type="menu" class="h4" @click="showDrawer" />
        <a-drawer
          placement="right"
          width="80%"
          :closable="false"
          :visible="visible"
          @close="onClose"
        >
          <img
            :src="this.manasLogo"
            alt=""
            width="130"
            @click="navigateTo('/')"
          />
          <div class="list-group list-group-flush mt-3">
            <a
              href="#"
              class="list-group-item list-group-item-action pl-0"
              @click="navigateTo('/contato')"
              >CONTATO</a
            >
          </div>
        </a-drawer>
      </div>
    </div>

    <a-layout-content>
      <div :style="{ minHeight: '380px' }">
        <slot />
      </div>
    </a-layout-content>
    <div class="bg-dark">
      <footer class="container my-md-5">
        <div class="row">
          <div class="col text-center">
            <img :src="this.manasLogo" width="150" />
          </div>
        </div>
      </footer>
      <div class="bg-success text-center p-2 mt-4">
        Festa Seguros © {{ year }}
      </div>
    </div>

    <div id="components-back-top-demo-custom">
      <a-back-top>
        <div class="ant-back-top-inner">
          <button type="button" class="btn btn-sm bg-success text-white">
            <a-icon type="vertical-align-top" style="font-size: 20px" />
          </button>
        </div>
      </a-back-top>
    </div>
  </a-layout>
</template>

<script>
import moment from "moment";
import router from "@/router/router";
import "@/filters/generic-filters/filter-ellipsis";
import Manas from "@/assets/img/logo-festa-seguros.png";
import { Constants } from "@/constants/constants";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "app",
  mounted() {
    this.$nextTick(() => {
      // this.loadNoticias({ size: 4, order: "asc" });
      // this.listarPostagensRecentes();
      // this.loadCategorias();
    });
  },
  computed: {
    ...mapGetters([
      "noticias",
      "loading",
      "categorias",
      "mulherLogada",
      "postagensRecentes",
    ]),
  },
  created() {
    this.$router.options.routes.forEach((route) => {
      this.items.push({
        path: route.path,
        name: route.name,
        meta: {
          guest: route.meta.guest,
          layout: route.meta.layout,
          pageName: route.meta.pageName,
          pageIcon: route.meta.pageIcon,
        },
      });
    });
  },
  data() {
    return {
      manasLogo: Manas,
      collapsed: false,
      constants: Constants,
      items: [],
      visible: false,
      year: moment().format("YYYY"),
    };
  },
  methods: {
    ...mapActions([
      "loadNoticias",
      "loadCategorias",
      "signOut",
      "listarPostagensRecentes",
    ]),
    loadAsset(path) {
      return require(`@/assets/img/${path}`);
    },
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    navigateTo(to) {
      this.visible = false;
      router.push(to);
    },
  },
};
</script>

<style>
.ant-layout-header {
  height: 64px;
  line-height: 64px;
  background: #ffffff;
}
.icons-list >>> .anticon {
  margin-right: 6px;
  font-size: 24px;
}

nav a.active {
  color: #5aa3d6 !important;
}
.txt-dark {
  color: #000;
}
</style>
