import axios from "axios";
import { Notification, NotificationError } from "@/util/noty";

export default {
  state: {
    stateEstadosCivis: [],
  },
  getters: {
    stateEstadosCivis: (state) => state.stateEstadosCivis,
  },
  mutations: {
    _setEstadosCivis(state, data) {
      state.stateEstadosCivis = data;
    },
  },
  actions: {
    actionListarEstadosCivis({ commit }) {
      axios
        .get(`${process.env.VUE_APP_API}/estados-civis`)
        .then((response) => {
          if (response.data.success) {
            commit("_setEstadosCivis", response.data.data.estados_civis);
            return response.data.data.estados_civis;
          } else {
            Notification(
              "danger",
              response.data.data.message.title,
              response.data.data.message.description
            );
          }
        })
        .catch((error) => {
          NotificationError();
          throw error.response.data;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
  },
};
