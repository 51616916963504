import moment from "moment";

export const formatarCpf = (cpf) => {
  return String(cpf)
    .replace(".", "")
    .replace(".", "")
    .replace("-", "");
};

export const formatarNascimento = (nascimento) => {
  nascimento = moment(nascimento, "DD/MM/YYYY");
  nascimento = moment(nascimento).format("YYYY-MM-DD");
  return nascimento;
};

export const formatarCelular = (celular) => {
  return String(celular)
    .replace("(", "")
    .replace(")", "")
    .replace("-", "");
};

export const formatarBoolean = (booleano) => {
  return booleano === "Sim" ? 1 : 0;
};

export const removePontuations = (str) => {
  str = String(str).replace(/[^\d]+/g, "");
  return str;
};
