import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const router = new VueRouter({
  routes: [
    {
      path: "/cotacao/categorias",
      name: "cotacao-categorias",
      component: () => import("@/views/landing/cotacao/categorias"),
      meta: {
        guest: true,
        layout: "layout-landing",
        pageName: "",
        pageIcon: "shop",
      },
    },
    {
      path: "/cotacao/seguro-para-automovel/:idCategoria",
      name: "cotacao-seguro-para-automovel",
      component: () => import("@/views/landing/cotacao/seguro-para-automovel"),
      meta: {
        guest: true,
        layout: "layout-landing",
        pageName: "",
        pageIcon: "shop",
      },
    },
    {
      path: "/cotacao/seguro-para-moto/:idCategoria",
      name: "cotacao-seguro-para-moto",
      component: () => import("@/views/landing/cotacao/seguro-para-moto"),
      meta: {
        guest: true,
        layout: "layout-landing",
        pageName: "",
        pageIcon: "shop",
      },
    },
    {
      path: "/cotacao/seguro-para-casa/:idCategoria",
      name: "cotacao-seguro-para-casa",
      component: () => import("@/views/landing/cotacao/seguro-para-casa"),
      meta: {
        guest: true,
        layout: "layout-landing",
        pageName: "",
        pageIcon: "shop",
      },
    },
    {
      path: "/",
      redirect: "/cotacao/categorias",
      component: () => import("@/views/landing/cotacao/categorias"),
      meta: {
        guest: false,
        layout: "layout-fullscreen",
        pageName: "Login",
        pageIcon: "lock",
      },
    },
  ],
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
