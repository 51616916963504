import Vuex from "vuex";
import Vue from "vue";

import categoriasSeguroStore from "./modules/categoriasSeguroStore";
import seguradorasStore from "./modules/seguradorasStore";
import categoriasStore from "./modules/categoriasStore";
import fipeStore from "./modules/fipeStore";
import cotacaoSeguroStore from "./modules/cotacaoSeguroStore";
import estadosCivisStore from "./modules/estadosCivisStore";
import viacepStore from "./modules/viacepStore";
import global from "./modules/global";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    categoriasSeguroStore,
    seguradorasStore,
    categoriasStore,
    fipeStore,
    cotacaoSeguroStore,
    estadosCivisStore,
    viacepStore,
    global,
  },
});
