import axios from "axios";
import { Notification, NotificationError } from "@/util/noty";
import { removePontuations } from "@/util/formatarPayload";

export default {
  state: {
    stateEnderecoDetalhes: [],
  },
  getters: {
    stateEnderecoDetalhes: (state) => state.stateEnderecoDetalhes,
  },
  mutations: {
    _setEnderecoDetalhes(state, data) {
      state.stateEnderecoDetalhes = data;
    },
  },
  actions: {
    actionListarEnderecoDetalhes({ commit }, cep) {
      return axios
        .get(
          `${process.env.VUE_APP_API_VIACEP}/${removePontuations(cep)}/json/`
        )
        .then((response) => {
          if (response.data.erro) {
            Notification(
              "danger",
              "Ooops!",
              "Endereço não encontrado para este CEP"
            );
            return response.data;
          } else {
            commit("_setEnderecoDetalhes", response.data);
            return response.data;
          }
        })
        .catch((error) => {
          NotificationError();
          throw error;
        })
        .finally(() => {
          commit("setLoading", false);
        });
    },
  },
};
