<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <component :is="layout">
        <router-view></router-view>
      </component>
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/pt_BR";
import { mapActions } from "vuex";
export default {
  name: "app",
  mounted() {
    this.$nextTick(() => {
      this.actionListarCategoriasSeguro();
      this.actionListarSeguradoras();
      this.actionListarEstadosCivis();
    });
  },
  computed: {
    layout() {
      return this.$route.meta.layout;
    },
  },
  data() {
    return {
      locale: zhCN,
    };
  },
  methods: {
    ...mapActions([
      "actionListarCategoriasSeguro",
      "actionListarSeguradoras",
      "actionListarEstadosCivis",
    ]),
  },
};
</script>
