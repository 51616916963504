<template>
  <a-layout id="components-layout-demo-fixed-sider">
    <a-layout-sider v-model="collapsed" :trigger="null" collapsible width="280">
      <div class="text-center mt-3 mb-3 pl-2 pr-2">
        <h3 v-if="!collapsed">Saúde das Manas</h3>
        <h3 v-else>LA</h3>
      </div>
      <a-menu theme="light" mode="inline" :default-selected-keys="['1']">
        <a-menu-item v-for="(item, key) in this.items" :key="key">
          <router-link :to="item.path" class="d-flex align-items-center">
            <a-icon :type="item.meta.pageIcon" />
            <span>{{ item.meta.pageName }}</span>
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header
        :style="{ position: 'fixed', zIndex: 1, width: '100%' }"
        class="bg-primary text-white shadow-sm"
      >
        <div class="row">
          <div class="col-1 w-10">
            <a-icon
              class="trigger"
              :type="collapsed ? 'right' : 'left'"
              @click="() => (collapsed = !collapsed)"
            />
          </div>
          <div class="col d-flex align-items-center">
            <div class="h3 p-0 m-0">{{ this.$route.meta.pageName }}</div>
          </div>
        </div>
      </a-layout-header>
      <a-layout-content class="p-4">
        <div :style="{ padding: '13px', marginTop: '50px' }">
          <a-breadcrumb class="mb-3">
            <a-breadcrumb-item>{{ constants.PROJECT }}</a-breadcrumb-item>
            <a-breadcrumb-item>{{
              this.$route.meta.pageName
            }}</a-breadcrumb-item>
          </a-breadcrumb>
          <slot />
        </div>
      </a-layout-content>
      <a-layout-footer class="bg-default" :style="{ textAlign: 'center' }">
        Festa Seguros ©2021
      </a-layout-footer>
      <a-back-top />
      <strong style="color: rgba(64, 64, 64, 0.6)"> </strong>
    </a-layout>
  </a-layout>
</template>

<script>
import Coronavirus from "@/assets/img/background-login.jpg";
import { Constants } from "@/constants/constants";
export default {
  name: "app",
  created() {
    this.$router.options.routes.forEach((route) => {
      this.items.push({
        path: route.path,
        name: route.name,
        meta: {
          guest: route.meta.guest,
          layout: route.meta.layout,
          pageName: route.meta.pageName,
          pageIcon: route.meta.pageIcon,
        },
      });
    });
  },
  data() {
    return {
      collapsed: false,
      constants: Constants,
      coronavirusImage: Coronavirus,
      items: [],
    };
  },
};
</script>

<style>
#components-layout-demo-fixed-sider .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
</style>
